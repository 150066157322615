import { Api } from "@quipa/api";
import { actionReducer } from "../../utils";
import { defaults, State } from "./model";

export default actionReducer<Api.RealtimeActionsMap, State>(defaults(), {
    [Api.LOGIN]: {
        success: (draft, { payload }) => {
            draft.token = payload.token;
            draft.refreshToken = payload.refreshToken;
            draft.firstName = payload.user.firstName;
            draft.lastName = payload.user.lastName;
            draft.email = payload.user.email;
            draft.id = payload.user.id;
            draft.role = payload.user.role;
        },
        failed: (draft) => {
            // actually we need to remove token only for 401 error code (payload.code)
            // and for others render "failed to load" screen or similar
            delete draft.token;
        },
    },
    [Api.SIGNUP]: {
        success: (draft, { payload }) => {
            draft.token = payload.token;
            draft.firstName = payload.user.firstName;
            draft.refreshToken = payload.refreshToken;
            draft.lastName = payload.user.lastName;
            draft.email = payload.user.email;
            draft.id = payload.user.id;
            draft.role = payload.user.role;
        },
    },
    [Api.LOGOUT]: {
        request: (draft) => {
            delete draft.token;
            delete draft.refreshToken;
        },
    },
    [Api.UPDATE_USER]: {
        request: (draft, { payload: { email, firstName, lastName } }) => {
            if (email) {
                draft.email = email;
            }
            if (firstName) {
                draft.firstName = firstName;
            }
            if (lastName) {
                draft.lastName = lastName;
            }
        },
    },
});
