// tslint:disable-next-line:no-reference
/// <reference path="./global.d.ts" />
// import { ApplicationModule, UserModule } from "./redux";
import { Config, createHistory, createStorage } from "./utils";
import { render, setLocale } from "./render";

import { RealtimeClient } from "./utils/realtime_client";
import configureStore from "./redux/configure_store";
import { parse } from "qs";
import { setDeployOptions } from "./utils/environment";

setLocale(window.navigator.language);

setDeployOptions((window as any).__DEPLOY_OPTIONS);

const browserHistory = createHistory("browser");
const { engine, loader } = createStorage();
// Analytic.initialize(Config.googleAnalyticId, Config.hubSpotAnalyticId, "web", getEnv().APPLICATION_VERSION);

async function load() {
    // let deviceId = window.localStorage.getItem("rambleDeviceId");
    // if (!deviceId) {
    //     deviceId =  uuid();
    //     window.localStorage.setItem("rambleDeviceId", deviceId);
    // }
    const urlParams = parse(
        document.location ? document.location.search.substring(1) : ""
    );
    const realtimePrefix = urlParams.realtime || "";
    const realtimeApi = new RealtimeClient(
        Config.realtimeUrl(realtimePrefix),
        ""
    );
    // const firebasePushApi = new FirebasePushApi(Config.pushApiKey, Config.googleProjectNumber);

    // firebasePushApi.onNotificationClicked = (uuid, action) => {
    //     browserHistory.push({
    //         pathname: `/chats/${uuid}`,
    //         query: action === "answer" ? { action } : undefined,
    //     });
    // };

    const store = configureStore(browserHistory, realtimeApi, engine);

    // realtimeApi.onConnecting = () => {
    //     store.dispatch(ApplicationModule.setRealtimeStatus("connecting"));
    // };
    // realtimeApi.onDisconnected = () => {
    //     store.dispatch(ApplicationModule.setRealtimeStatus("disconnected"));
    // };
    // realtimeApi.onConnected = async reconnected => {
    //     store.dispatch(ApplicationModule.setRealtimeStatus("connected"));
    //     if (reconnected && store.getState().user.token) {
    //         const token = store.getState().user.token!;
    //         await store.dispatch(UserModule.loginByToken(token));
    //     }
    // };
    // realtimeApi.onServerAction = action => {
    //     store.dispatch(action);
    // };
    // realtimeApi.connect();

    const rootEl = document.getElementById("quipa-portal");

    await loader(store);
    render(rootEl, browserHistory, store);
}

load();
