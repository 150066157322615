import React, {
    ChangeEvent,
    createRef,
    RefObject,
    useEffect,
    useState,
} from "react";
import styled from "../../theme";
import TrashIcon from "../../assets/icons/trash.svg";
import DownloadIcon from "../../assets/icons/download_icon.svg";
import MaximizeIcon from "../../assets/icons/maximize_icon.svg";
import DefaultImageTest from "../../assets/images/default_avatar.png";
import CloseIcon from "../../assets/icons/pop_up_close.svg";
import UploadNewIcon from "../../assets/icons/photograph_upload_icon.svg";
import { FiRotateCw } from "react-icons/fi";
import { Config } from "../../utils";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

type UploadTabProps = {
    disabled: boolean;
    handleAddUploads?(uploads: any[], newUploads?: boolean): void;
    draperyOrderUploads: { id: number; items: any[] };
};

const UploadContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, minmax(200px, 1fr));
    column-gap: 28px;
    row-gap: 48px;
    padding-right: 20px;
    margin-top: 48px;
`;

const UploadBox = styled.div`
    border: 1px solid #e2e2e2;
    box-shadow: 0 4px 16px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    position: relative;

    & .maximize_icon,
    & .close_icon {
        position: absolute;
        top: 4px;
        right: 4px;
        cursor: pointer;
    }

    & .close_icon {
        top: 10px;
        right: 10px;
        transform: scale(1.4) rotate(0deg);
        transition: all 0.3s ease;

        &:hover {
            transform: scale(1.4) rotate(90deg);
        }
    }

    &.maximized {
        height: 100%;
    }
`;

const UploadNewBox = styled.div<{
    dragActive?: boolean;
}>`
    border: 1px solid #e2e2e2;
    box-shadow: 0 4px 16px 0px rgba(0, 0, 0, 0.12);
    height: 413px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;

    & .newUploadBox {
        user-select: none;
        display: flex;
        align-items: center;
        flex-direction: column;
        border: 2px dashed #d4d4d4;
        border-radius: 12px;
        padding: 12px;
        text-align: center;
        background-color: ${(props) =>
            props.dragActive ? "#f0f0f0" : "white"};
        width: 250px;
        min-height: 200px;
        max-height: 413px;

        & span {
            font-size: 14px;
        }

        & .from_computer {
            text-decoration: underline;
            color: ${({ theme }) => theme.colors.primary};
            cursor: pointer;
            user-select: none;

            &.disabled {
                cursor: default;
                color: #d4d4d4;
            }
        }

        & .cropper {
            margin-bottom: 12px;
            margin-top: 12px;

            &.noMargin {
                margin: 0;
            }

            .cropper-container {
                background: #f2f2f2;
            }
            .cropper-modal {
                background: #fff;
            }
            .rounded-image-select .cropper-view-box {
                border: 1px solid #00b2ff;
            }
            .cropper-point {
                color: inherit;
            }
        }
    }
`;

const UploadImagePreview = styled.div`
    background-color: #f6f6f6;
    height: 229px;

    padding: 0px 28px;

    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }

    &.maximized {
        display: flex;
        justify-content: center;
        flex: 1.45;

        & img {
            width: unset;
        }
    }
`;

const UploadBoxContent = styled.div`
    height: 184px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: white;

    &.maximized {
        height: 100%;
        flex: 1;
    }
`;

const UploadBoxContentDesc = styled.div`
    height: 100%;

    & textarea {
        height: 100%;
        width: 100%;
        border: 1px solid #9d9d9d;
        border-radius: 4px;
        padding: 10px;
        outline: none;
        resize: none;

        &.readonly {
            cursor: default;
            background: white;
            border-color: #e2e2e2;
        }

        &:disabled {
            background: white;
            border-color: #e2e2e2;
        }
    }
`;

const UploadBoxAction = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 24px;
    height: 100%;

    & > button {
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;

        &.disabled {
            opacity: 0.5;
            cursor: default;
        }

        & .actionName {
            color: #545454;
            font-weight: 500;
            font-size: 14px;
            line-height: normal;
        }
    }
`;

const UploadBoxMaximized = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    height: 80%;
    width: 80%;
    z-index: 1000;
    opacity: 0;
    transition: all 0.2s ease-out;

    &.active {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
`;

const UploadBoxMaximizedOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    opacity: 0.4;
    z-index: 999;
    display: none;

    &.active {
        display: block;
    }
`;

export const duplicateUploadTab = (draperyOrderUpload: any) => {
    return {
        ...draperyOrderUpload,
    };
};

export const dataURItoBlob = (dataURI: string) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], { type: mimeString });
    return blob;
};

const UploadTab = (props: UploadTabProps) => {
    const { disabled, handleAddUploads, draperyOrderUploads } = props;
    const { items } = draperyOrderUploads;

    // file upload and manage upload states
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
    const [uploadError, setUploadError] = useState<string | null>(null);
    const [dragActive, setDragActive] = useState<boolean>(false);
    const [rotateImage, setRotateImage] = useState<number>(0);
    const [zoomImage, setZoomImage] = useState<number>(0);
    const [imageUploads, setImageUploads] = useState<any[]>([]);
    // refs
    const cropperRef: RefObject<any> = createRef();
    // maximize upload
    const [maximized, setMaximized] = useState<boolean>(false);
    // set upload for maximization
    const [upload, setUpload] = useState<any | null>(null);

    useEffect(() => {
        setUploadError(null);
    }, [disabled]);

    // download upload
    const handleDownloadUpload = async (u: any) => {
        try {
            const src = u.fromRest
                ? `${Config.fileStoreUrl}${u.source}`
                : u.source;
            const response = await fetch(src);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = u.name;
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Error downloading image:", error);
        }
    };

    // delete upload
    const handleDeleteUpload = (item: any) => {
        if (disabled) {
            return;
        }

        if (items && items.length > 0) {
            const filteredArray = items.filter((_, i) => i !== item.index);
            if (handleAddUploads) {
                handleAddUploads(filteredArray, false);
            }
            handleCloseMaximized();
        }
    };

    // minimize image
    const handleCloseMaximized = () => {
        setMaximized(false);
        setUpload(null);
    };
    // maximize image
    const handleOpenMaximized = (item: any) => {
        setMaximized(true);
        setUpload(item);
    };
    // drag over upload
    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (disabled) return;
        setDragActive(true);
    };
    // drag leave upload
    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (disabled) return;
        setDragActive(false);
    };
    // drag and drop upload
    const handleDragDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (disabled) return;
        setDragActive(false);

        const files = e.dataTransfer.files;

        handleFile(files);
    };

    // trigger upload
    const handleChooseFile = () => {
        if (disabled) return;

        const inputEl = document.getElementById("fileInput");
        if (inputEl) {
            inputEl.click();
        }
    };

    // upload file or files
    const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files || [];

        handleFile(files);
    };

    const convertToPng = async (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                if (!ctx) {
                    reject("Canvas context is not supported.");
                    return;
                }

                canvas.width = image.width;
                canvas.height = image.height;

                ctx.drawImage(image, 0, 0);

                canvas.toBlob(
                    (blob) => {
                        if (!blob) {
                            reject("Conversion to PNG failed.");
                            return;
                        }

                        const pngFile = new File(
                            [blob],
                            file.name.replace(/\.\w+$/, ".png")
                        );

                        const pngUrl = URL.createObjectURL(pngFile);

                        resolve(pngUrl);
                    },
                    "image/png",
                    1
                );
            };

            image.onerror = () => {
                reject("Image loading failed.");
            };

            image.src = URL.createObjectURL(file);
        });
    };

    const handleFile = async (files: File[] | FileList) => {
        if (files && files.length > 0) {
            setUploadError(null);
            let newUploadArray: any[] = [];

            for (let i = 0; i < files.length; i++) {
                const allowedExtensions = ["jpeg", "jpg", "png", "svg", "webp"];
                const fileName = files[i].name.toLowerCase();
                const isValidExtension = allowedExtensions.some((ext) =>
                    fileName.endsWith(ext)
                );

                if (isValidExtension) {
                    // payload
                    let obj: any = {};

                    if (
                        fileName.endsWith(".svg") ||
                        fileName.endsWith(".webp")
                    ) {
                        if (files[i].size / 1024 > 10240) {
                            setUploadError("Max allowed size is 10MB.");
                        } else {
                            try {
                                const pngUrl = await convertToPng(files[i]);

                                obj = files[i];
                                obj.source = pngUrl;
                                obj.description = "";
                                obj.fromRest = false;
                            } catch (error) {
                                console.error(error);
                            }
                        }
                    } else {
                        if (files[i].size / 1024 > 10240) {
                            setUploadError("Max allowed size is 10MB.");
                        } else {
                            obj = files[i];
                            obj.source = URL.createObjectURL(files[i]);
                            obj.description = "";
                            obj.fromRest = false;
                        }
                    }

                    if (Object.keys(obj).length > 0) {
                        newUploadArray.push(obj);
                    }
                } else {
                    setUploadError("Valid image file (JPEG, PNG, SVG).");
                }
            }

            setImageUploads(newUploadArray);

            setCurrentImageIndex(0);
            setRotateImage(0);
        }
    };

    // crop image change
    const handleCropChange = async () => {
        if (!cropperRef || !cropperRef.current || !cropperRef.current.cropper) {
            return;
        }
        const croppedCanvas =
            await cropperRef.current.cropper.getCroppedCanvas();

        if (croppedCanvas) {
            const croppedImgData = await croppedCanvas.toDataURL();

            if (croppedImgData) {
                setImageUploads((prev) => {
                    prev[currentImageIndex].source = croppedImgData;
                    return prev;
                });
                return true;
            }
        }
    };

    // rotate image
    const handleRotateImage = () => {
        setRotateImage((prev) => {
            return prev + 1;
        });

        if (cropperRef && cropperRef.current && cropperRef.current.cropper) {
            cropperRef.current.cropper.rotate(90);
        }
    };

    // zoom image
    const changeZoom = (e: ChangeEvent<HTMLInputElement>) => {
        if (!cropperRef || !cropperRef.current || !cropperRef.current.cropper) {
            return;
        }

        const zoomValue = e.currentTarget.valueAsNumber;
        const zoomLevel = zoomValue / 100;

        setZoomImage(zoomValue);

        cropperRef.current.cropper.zoomTo(zoomLevel);
    };

    // go to next image
    const handleNextImage = async () => {
        if (
            imageUploads &&
            imageUploads.length &&
            imageUploads.length - 1 === currentImageIndex
        )
            return;
        setCurrentImageIndex((prev) => {
            return prev + 1;
        });
        await handleCropChange();
        setRotateImage(0);
    };

    const handleSaveUploads = async () => {
        if (await handleCropChange()) {
            // setUploads(prev => {
            //   return [
            //     ...prev,
            //     ...imageUploads
            //   ]
            // })
            if (handleAddUploads) {
                handleAddUploads(imageUploads, true);
            }
            setImageUploads([]);
            setUploadError(null);
            setCurrentImageIndex(0);
            setRotateImage(0);
            setZoomImage(0);
        }
    };

    const handleDeleteCurrentImage = () => {
        const filteredArray =
            imageUploads && imageUploads.length > 0
                ? imageUploads.filter((_, index) => index !== currentImageIndex)
                : [];
        setImageUploads(filteredArray);

        if (
            filteredArray &&
            filteredArray.length > 0 &&
            currentImageIndex !== 0
        ) {
            if (currentImageIndex >= filteredArray.length) {
                setCurrentImageIndex((prev) => prev - 1);
            }
        } else {
            setCurrentImageIndex(0);
        }
    };

    const handleChangeUploadDescription = (
        e: React.ChangeEvent<HTMLTextAreaElement>,
        index: number
    ) => {
        const updatedUploads = [...items];
        updatedUploads[index].description = e.target.value;
        if (handleAddUploads) {
            handleAddUploads(updatedUploads, false);
        }
    };

    const handleEnterPressed = (
        e: React.KeyboardEvent<HTMLTextAreaElement>
    ): void => {
        const key = e.key;
        if (key === "Enter" || key === "Return") {
            e.preventDefault();

            const textarea = e.currentTarget;
            const value = textarea.value;
            const selectionStart = textarea.selectionStart;
            const selectionEnd = textarea.selectionEnd;

            const part1 = value.slice(0, selectionStart);
            const part2 = value.slice(selectionEnd);

            const newValue = part1 + "\n" + part2;

            textarea.value = newValue;
            textarea.selectionStart = textarea.selectionEnd =
                selectionStart + 1;
        }
    };

    useEffect(() => {
        if(disabled){
          setImageUploads([])
        }
      }, [disabled])
    

    return (
        <UploadContainer>
            {items &&
                items.length > 0 &&
                items.map((u: any, idx) => {
                    const url = u.fromRest
                        ? `${Config.fileStoreUrl}${u.source}`
                        : u.source;
                    const uploadModified = {
                        ...u,
                        index: idx,
                        name: u.name,
                    };
                    return (
                        <UploadBox key={idx}>
                            <MaximizeIcon
                                onClick={() =>
                                    handleOpenMaximized(uploadModified)
                                }
                                className="maximize_icon"
                            />

                            <UploadImagePreview>
                                <img
                                    draggable={false}
                                    src={url || DefaultImageTest}
                                />
                            </UploadImagePreview>

                            <UploadBoxContent>
                                <UploadBoxContentDesc>
                                    <textarea
                                        disabled={disabled}
                                        onKeyDown={handleEnterPressed}
                                        onChange={(e) =>
                                            handleChangeUploadDescription(
                                                e,
                                                idx
                                            )
                                        }
                                        value={u.description || ""}
                                    />
                                </UploadBoxContentDesc>

                                <UploadBoxAction>
                                    <button
                                        type="button"
                                        onClick={() => handleDownloadUpload(u)}
                                    >
                                        <DownloadIcon />
                                        <span className="actionName">
                                            Download
                                        </span>
                                    </button>

                                    <button
                                        className={disabled ? "disabled" : ""}
                                        disabled={disabled}
                                        type="button"
                                        onClick={() =>
                                            handleDeleteUpload(uploadModified)
                                        }
                                    >
                                        <TrashIcon
                                            style={{
                                                height: "24px",
                                                width: "24px",
                                            }}
                                        />
                                        <span className="actionName">
                                            Delete
                                        </span>
                                    </button>
                                </UploadBoxAction>
                            </UploadBoxContent>
                        </UploadBox>
                    );
                })}

            <UploadNewBox dragActive={dragActive}>
                <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    multiple
                    style={{ display: "none" }}
                    onChange={(e) => handleChangeFile(e)}
                />

                <div
                    onDrop={handleDragDrop}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    className="newUploadBox"
                >
                    <div
                        className={`cropper ${
                            imageUploads && imageUploads.length > 0
                                ? "noMargin"
                                : ""
                        }`}
                    >
                        {imageUploads && imageUploads.length > 0 ? (
                            <>
                                <Cropper
                                    ref={cropperRef}
                                    src={imageUploads[currentImageIndex].source}
                                    guides={false}
                                    viewMode={2}
                                    dragMode={"move"}
                                    className={"rounded-image-select"}
                                    minCropBoxWidth={undefined}
                                    minCropBoxHeight={undefined}
                                    toggleDragModeOnDblclick={false}
                                    style={{ width: "240px", height: "250px" }}
                                />
                            </>
                        ) : (
                            <UploadNewIcon />
                        )}
                    </div>

                    {imageUploads && imageUploads.length > 0 && (
                        <div
                            draggable={false}
                            style={{
                                pointerEvents: dragActive ? "none" : "all",
                                userSelect: "none",
                            }}
                        >
                            <button
                                type="button"
                                onClick={() => handleDeleteCurrentImage()}
                            >
                                Delete
                            </button>
                        </div>
                    )}

                    {imageUploads && imageUploads.length > 0 && (
                        <div
                            className="flex items-center"
                            style={{
                                pointerEvents: dragActive ? "none" : "all",
                                userSelect: "none",
                                gap: "6px",
                            }}
                            draggable={false}
                        >
                            <button
                                type="button"
                                onClick={() => handleRotateImage()}
                            >
                                <FiRotateCw
                                    style={{
                                        fontSize: "20px",
                                        transform: `rotate(${
                                            rotateImage * 90
                                        }deg)`,
                                        transition: "all .15s ease",
                                    }}
                                />
                            </button>
                            <input
                                min={0}
                                max={1000}
                                step={1}
                                value={zoomImage}
                                onChange={(e) => changeZoom(e)}
                                style={{
                                    pointerEvents: dragActive ? "none" : "all",
                                    userSelect: "none",
                                }}
                                type="range"
                            />
                            {imageUploads.length > 1 &&
                            imageUploads.length - 1 !== currentImageIndex ? (
                                <button
                                    onClick={() => handleNextImage()}
                                    type="button"
                                >
                                    <span
                                        style={{
                                            fontWeight: "500",
                                            fontSize: "14px",
                                        }}
                                    >
                                        Next
                                    </span>
                                </button>
                            ) : (
                                <button
                                    onClick={() => handleSaveUploads()}
                                    type="button"
                                >
                                    <span
                                        style={{
                                            fontWeight: "500",
                                            fontSize: "14px",
                                        }}
                                    >
                                        Save
                                    </span>
                                </button>
                            )}
                        </div>
                    )}

                    <span
                        draggable={false}
                        style={{
                            marginBottom: "8px",
                            userSelect: "none",
                            pointerEvents: "none",
                            color: disabled ? "#d4d4d4" : "unset",
                        }}
                    >
                        Drag and drop file here or
                    </span>

                    <span
                        style={{
                            pointerEvents: dragActive ? "none" : "all",
                            userSelect: "none",
                        }}
                        draggable={false}
                        onClick={handleChooseFile}
                        className={`from_computer ${
                            disabled ? "disabled" : ""
                        }`}
                    >
                        Choose from computer
                    </span>

                    {uploadError && (
                        <span
                            draggable={false}
                            style={{
                                color: "#FF6084",
                                pointerEvents: "none",
                                userSelect: "none",
                            }}
                        >
                            {uploadError}
                        </span>
                    )}
                </div>
            </UploadNewBox>

            <UploadBoxMaximized className={maximized ? "active" : ""}>
                <UploadBox className="maximized">
                    <CloseIcon
                        onClick={() => handleCloseMaximized()}
                        className="close_icon"
                    />

                    <UploadImagePreview className="maximized">
                        {upload && upload.source && (
                            <img
                                draggable={false}
                                src={
                                    upload && upload.fromRest
                                        ? `${Config.fileStoreUrl}${upload.source}`
                                        : upload.source
                                }
                            />
                        )}
                    </UploadImagePreview>

                    <UploadBoxContent className="maximized">
                        <UploadBoxContentDesc>
                            <textarea
                                className="readonly"
                                readOnly
                                value={
                                    upload && upload.description
                                        ? upload.description
                                        : ""
                                }
                            />
                        </UploadBoxContentDesc>

                        <UploadBoxAction>
                            <button
                                type="button"
                                onClick={() => handleDownloadUpload(upload)}
                            >
                                <DownloadIcon />
                                <span className="actionName">Download</span>
                            </button>

                            <button
                                disabled={disabled}
                                className={disabled ? "disabled" : ""}
                                type="button"
                                onClick={() => handleDeleteUpload(upload)}
                            >
                                <TrashIcon
                                    style={{ height: "24px", width: "24px" }}
                                />
                                <span className="actionName">Delete</span>
                            </button>
                        </UploadBoxAction>
                    </UploadBoxContent>
                </UploadBox>
            </UploadBoxMaximized>

            <UploadBoxMaximizedOverlay
                onClick={() => handleCloseMaximized()}
                className={maximized ? "active" : ""}
            />
        </UploadContainer>
    );
};

export default UploadTab;
