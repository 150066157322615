import {
    Button,
    FormField,
    FormValidatorErrorMap,
    ValidationForm,
    ValidationPopupStateInput,
} from "@ramble/ramble-ui";
import Bind from "lodash-decorators/bind";
import React, { FormEvent, PureComponent } from "react";
import { connect, DispatchProp } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { AuthDescription, AuthHeader } from "../components/auth/auth_header";
import { Logo } from "../components/auth/logo";
import { RouteLink } from "../components/navigation/route_link";
import {
    RootState,
    //  UserModule
} from "../redux";
import styled, { css } from "../theme";
import { FaEye } from "react-icons/fa";
import { passwordReset } from "../api/password-reset";

// tslint:disable-next-line:no-empty-interface
export interface PasswordResetOwnProps extends RouteComponentProps {}

export interface PasswordResetProps extends PasswordResetOwnProps {
    /**
     * Reset code if available (usually provided from URL when clicking at the reset link)
     */
    resetCode?: string;
}

const SigninLink = styled(RouteLink)`
    font-size: 0.8em;
`;

const InputErrorMessage = styled.div`
    color: red;
    font-size: 13px;
    p {
        margin-top: 7px !important;
    }
`;
const ValidationPopupStateInputFirst = styled(ValidationPopupStateInput)`
    ${(props) =>
        props.pattern &&
        css`
            border-color: ${props.isValid && "hsl(0,71.3%,76.2%)"};
            background-color: ${props.isValid && "hsl(0,71.3%,96.5%)"};
            &:focus-within {
                border-color: ${props.isValid &&
                "hsl(0,71.3%,76.2%)"} !important;
                color: ${props.isValid && "hsl(0,71.3%,76.2%)"} !important;
            }
        `}
`;

interface PasswordResetState {
    valueNew: boolean;
    valueResetNew: boolean;
    string: string;
    stringNew: string;
    password: string;
    isReset: boolean;
    emptyPassword: boolean;
}

export class PasswordReset extends PureComponent<
    PasswordResetProps & DispatchProp,
    PasswordResetState
> {
    public constructor(props: PasswordResetProps & DispatchProp) {
        super(props);
        this.state = {
            valueNew: false,
            valueResetNew: false,
            string: "",
            stringNew: "",
            password: "",
            isReset: false,
            emptyPassword: false,
        };
    }
    public render(): JSX.Element {
        return (
            <>
                <Logo />
                <AuthDescription>
                    We sent you an email with a reset code.
                </AuthDescription>
                <AuthHeader uiSize="1em">
                    Reset Password
                    <SigninLink to="/login">Back to Login</SigninLink>
                </AuthHeader>
                {this.renderForm()}
            </>
        );
    }

    public renderForm(): JSX.Element {
        const { resetCode } = this.props;
        return (
            <ValidationForm
                uiOnSubmit={this.submit}
                uiPadding={false}
                uiSubmitFailedIcon
                uiSubmitFailedMessage="Invalid reset code provided"
                uiReportValidityMode="firstInvalid"
                uiFormValidator={this.formValidator}
                uiDimmerBlurring={false}
                autoComplete="new-password"
            >
                <FormField uiRequired>
                    <ValidationPopupStateInput
                        id="reset_code"
                        name="reset_code"
                        tabIndex={1}
                        required
                        minLength={8}
                        maxLength={8}
                        pattern="[a-zA-Z0-9]*"
                        placeholder="Reset Code"
                        uiPopupPosition="bottom right"
                        uiReportOnBlur={false}
                        autoFocus
                        defaultValue={resetCode}
                        autoComplete="new-password"
                        uiValidityMessages={{
                            patternMismatch:
                                "Only digits and letters are allowed",
                        }}
                    />
                </FormField>
                <FormField uiRequired>
                    <ValidationPopupStateInputFirst
                        id="password"
                        name="password"
                        type="password"
                        tabIndex={1}
                        required
                        minLength={8}
                        maxLength={255}
                        // autoComplete="new-password"
                        // uppercase, lowercase, number/special char and min 8 chars
                        pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                        placeholder="New Password"
                        uiPopupPosition="bottom right"
                        defaultValue=""
                        uiReportOnBlur={false}
                        onChange={this.handleChangePassword}
                        isValid={
                            !this.state.valueNew && this.state.string.length > 0
                        }
                        handleEmptyInputPassword={this.handleEmptyInputPassword}
                        // uiValidityMessages={{
                        //     tooShort: "Must be at least 8 characters.",
                        //     patternMismatch:
                        //         "Must include both uppercase and lowercase letters and number or special character.",
                        // }}
                        popupString={true}
                    />
                    <InputErrorMessage>
                        {((!this.state.valueNew &&
                            this.state.string.length > 0) ||
                            this.state.emptyPassword) && (
                            <p>
                                Must contain at least 8 characters including an
                                uppercase letter, lowercase letter, and a number
                                or special character.
                            </p>
                        )}
                    </InputErrorMessage>
                    <FaEye
                        onClick={() => this.handleShowPassword("password")}
                        style={{
                            position: "absolute",
                            top: "14px",
                            right: "10px",
                        }}
                    />
                </FormField>
                <FormField uiRequired>
                    <ValidationPopupStateInput
                        id="password_repeat"
                        name="password_repeat"
                        type="password"
                        tabIndex={2}
                        maxLength={255}
                        // uppercase, lowercase, number/special chars and min 8 chars
                        placeholder="Confirm New Password"
                        uiPopupPosition="bottom right"
                        defaultValue=""
                        autoComplete="new-password"
                        uiReportOnBlur={false}
                        onChange={this.handleChangePasswordRepeat}
                        popupString={true}
                    />
                    <InputErrorMessage>
                        {this.state.password && this.state.valueNew && (
                            <p>{this.state.password}</p>
                        )}
                    </InputErrorMessage>
                    <FaEye
                        onClick={() =>
                            this.handleShowPassword("password_repeat")
                        }
                        style={{
                            position: "absolute",
                            top: "14px",
                            right: "10px",
                        }}
                    />
                </FormField>
                <Button uiColor="primary" type="submit" tabIndex={3}>
                    Reset Password
                </Button>
            </ValidationForm>
        );
    }

    @Bind()
    private handleShowPassword(id: string) {
        const eye = document.getElementById(id)! as HTMLInputElement;
        if (eye.type === "password") {
            eye.type = "text";
        } else {
            eye.type = "password";
        }
    }

    @Bind()
    public handleEmptyInputPassword() {
        this.setState({ emptyPassword: true });
    }

    @Bind()
    private handleChangePassword(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        this.setState({ string: value });
        const regex =
            /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
        if (value.match(regex)) {
            this.setState({ valueNew: true, emptyPassword: false });
        } else {
            this.setState({ valueNew: false });
        }
    }
    @Bind()
    private handleChangePasswordRepeat(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        this.setState({ stringNew: value });
        const regex =
            /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
        if (value.match(regex)) {
            this.setState({ valueResetNew: true });
        } else {
            this.setState({ valueResetNew: false });
        }
    }

    @Bind()
    private async submit(e: FormEvent<HTMLFormElement>): Promise<void> {
        const {
            //  dispatch,
            history,
        } = this.props;
        // tslint:disable-next-line:no-string-literal
        const code = (
            e.currentTarget.elements["reset_code"] as HTMLInputElement
        ).value.trim();
        // tslint:disable-next-line:no-string-literal
        const password = (
            e.currentTarget.elements["password"] as HTMLInputElement
        ).value;

        const res = await passwordReset(code, password);

        // const res = await dispatch(UserModule.passwordReset(code, password));
        if (res.error) {
            throw new Error(res.payload.message);
        }
        // Should we autologin user after providing new password??
        history.push("/login");
    }

    @Bind()
    private formValidator(elem: HTMLFormElement): FormValidatorErrorMap {
        // tslint:disable-next-line:no-string-literal
        const password = (elem.elements["password"] as HTMLInputElement).value;
        // tslint:disable-next-line:no-string-literal
        const repeatPassword = (
            elem.elements["password_repeat"] as HTMLInputElement
        ).value;
        if (password !== repeatPassword) {
            this.setState({ password: "Passwords must match" });
            return {
                password_repeat: "Passwords must match",
            };
        }
        this.setState({ password: "" });

        return {};
    }
}

function mapStateToProps(
    store: RootState,
    ownProps: PasswordResetOwnProps
): PasswordResetProps {
    const code = ownProps.location.query.code || "";
    return {
        ...ownProps,
        resetCode: code,
    };
}

export const PasswordResetContainer = connect(mapStateToProps)(PasswordReset);
