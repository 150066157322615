import {
    FormFields,
    FormFieldsPartial,
} from "../redux/modules/my-shop/form-fields-reducer";

import { DraperyItemDetailsFieldsPartial } from "../redux/modules/my-shop/drapery-item-details-fields-reducer";
import { FabricsOrLiningsItemDetailsFieldsPartial } from "../redux/modules/my-shop/fabrics-or-linings-item-details-fields-reducer";
import { GetAllQueryParams } from "../interfaces/item";
import axiosInstance from "./axios-instance";

type ComboDraperyPayload = FormFieldsPartial | DraperyItemDetailsFieldsPartial;
type ComboFabricsOrLinings =
    | FormFieldsPartial
    | FabricsOrLiningsItemDetailsFieldsPartial;

const getAll = async (queryParams?: GetAllQueryParams) => {
    try {
        const response = await axiosInstance.get<FormFields[]>("/item", {
            params: queryParams,
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching items:", error);
        throw error;
    }
};

const getRecord = async ({ recordId }: { recordId: number }) => {
    try {
        const response = await axiosInstance.get<FormFields>(
            `/item/${recordId}`
        );
        return response.data;
    } catch (error) {
        console.error("Error adding new item:", error);
        throw error;
    }
};

const addRecord = async (payload: FormFieldsPartial) => {
    try {
        const response = await axiosInstance.post<FormFieldsPartial>(
            "/item",
            payload
        );
        return response;
    } catch (error) {
        console.error("Error adding new item:", error);
        throw error;
    }
};

const updateRecord = async (payload: FormFieldsPartial) => {
    try {
        const response = await axiosInstance.put<FormFieldsPartial>(
            `/item/${payload.id}`,
            payload
        );
        return response;
    } catch (error) {
        console.error("Error updating item:", error);
        throw error;
    }
};

const updateInactiveBulkRecord = async (payload: number[]) => {
    try {
        const response = await axiosInstance.put<FormFieldsPartial>(
            `/item`,
            payload
        );
        return response;
    } catch (error) {
        console.error("Error updating item:", error);
        throw error;
    }
};

const getDraperyRecord = async ({ recordId }: { recordId: number }) => {
    try {
        const response = await axiosInstance.get<ComboDraperyPayload>(
            `/item/drapery/${recordId}`
        );

        return response.data;
    } catch (error) {
        console.error("Error fetching drapery item:", error);
        throw error;
    }
};

const addDraperyRecord = async (payload: ComboDraperyPayload) => {
    try {
        const response = await axiosInstance.post<ComboDraperyPayload>(
            "/item/drapery",
            payload
        );
        return response;
    } catch (error) {
        console.error("Error adding new drapery item:", error);
        throw error;
    }
};

const updateDraperyRecord = async (payload: ComboDraperyPayload) => {
    try {
        const response = await axiosInstance.put<ComboDraperyPayload>(
            `/item/drapery/${payload.id}`,
            payload
        );
        return response;
    } catch (error) {
        console.error("Error updating drapery item:", error);
        throw error;
    }
};

const deletePleat = async (itemId: number | null, newPleatId: any) => {
    try {
        const response = await axiosInstance.put<ComboDraperyPayload>(
            `/item/pleats/${newPleatId}/${itemId}`
        );        
        return response.data; 
    } catch (error) {
        console.error("Error deleting pleat:", error);
        throw error;
    }
};


const getFabricsOrLiningsRecord = async ({
    recordId,
    draperyOrderFabricId
}: {
    recordId: number;
    draperyOrderFabricId?: number;
}) => {
    try {
        const response = await axiosInstance.get<ComboFabricsOrLinings>(
           `/item/fabrics-or-linings/${recordId}${draperyOrderFabricId ? `?id=${draperyOrderFabricId}` : ""}`
        );

        return response.data;
    } catch (error) {
        console.error("Error fetching fabrics or linings item:", error);
        throw error;
    }
};

const addFabricsOrLiningsRecord = async (payload: ComboFabricsOrLinings) => {
    try {
        const response = await axiosInstance.post<ComboFabricsOrLinings>(
            "/item/fabrics-or-linings",
            payload
        );
        return response;
    } catch (error) {
        console.error("Error adding new fabrics or linings item:", error);
        throw error;
    }
};

const updateFabricsOrLiningsRecord = async (payload: ComboFabricsOrLinings) => {
    try {
        const response = await axiosInstance.put<ComboFabricsOrLinings>(
            `/item/fabrics-or-linings/${payload.id}`,
            payload
        );
        return response;
    } catch (error) {
        console.error("Error updating fabrics or linings item:", error);
        throw error;
    }
};

const seedItemsOnSignup = async () => {
        try {
            const response = await axiosInstance.post("/item/seed-on-signup");
            return response.data;
    } catch (error) {
        console.error("Error seeding items on signup:", error);
        throw error;
    }
};

export {
    getAll,
    getRecord,
    addRecord,
    updateRecord,
    updateInactiveBulkRecord,
    getDraperyRecord,
    addDraperyRecord,
    updateDraperyRecord,
    deletePleat,
    getFabricsOrLiningsRecord,
    addFabricsOrLiningsRecord,
    updateFabricsOrLiningsRecord,
    seedItemsOnSignup,
};
