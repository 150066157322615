import {
    Button,
    FormField,
    FormValidationError,
    PageDimmer,
    ValidationForm,
    ValidationPopupInput,
    ValidationPopupStateInput,
    ValidityMessagesMap,
} from "@ramble/ramble-ui";
import Bind from "lodash-decorators/bind";
import React, { FormEvent, PureComponent } from "react";
import { connect, DispatchProp } from "react-redux";
import { ContentSection } from "../components/content/content_section";
import { PageContent } from "../components/content/page_content";
import { PageHeader } from "../components/content/page_header";
import { UpdatePasswordModal } from "../modals/update_password_modal";
import { RootState } from "../redux";
import styled from "../theme";
import { updateCrispEmail, getCrispProfile } from "../api/crisp";
import { NotificationsModule } from "../redux";
import { Crisp } from "crisp-sdk-web";
import { VContainer, VSpace } from "../components/UI/more_detail/container";
import { FormSubtitle, PencilEdit, TrashDelete } from "./account_settings";
import { UserAvatar } from "../components/UI/avatar";
import { ValidationError } from "@quipa/api";
import { AvatarModal } from "../modals/avatar_modal";
import { handleUpdateUser, updateUsersPassword } from "../api/users";
import { uploadImage } from "../api/account";
import { Config } from "../utils";
import { validateEmail } from "../api/signup";
export interface UserSettingsProps {
    email: string;
    firstName: string;
    lastName: string;
    accountId: number;
    logoId?: string | null;
}

const UserForm = styled(ValidationForm)`
    max-width: 500px;
`;

const UpdatePasswordButton = styled(Button)`
    margin-top: 1em;
    color: white;
`;
const GrayBorder = styled.span`
    padding: 11px 0 6px;
    border: 1px solid #969696;
    margin: 0 10px 0 15px;
`;
const AvatarContainer = styled.div`
    flex: 1;
   change: Api.UpdateUserAction[Req], undefined: undefined, undefined: undefined, undefined: undefined, p0: null, p1: null350px;
    width: 100%;
    margin-top: 12px;
`;
const AvatarActionContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
`;
interface State {
    updatePasswordActive: boolean;
    imageModalOpen: boolean;
    logoId?: string | null;
}

export class UserSettings extends PureComponent<
    UserSettingsProps & DispatchProp,
    State
> {
    public state: State = {
        updatePasswordActive: false,
        imageModalOpen: false,
        logoId: this.props.logoId,
    };

    private validationMessages: ValidityMessagesMap<"username" | "email"> = {
        username: {
            nonUnique: "An account with that Username already exists",
            patternMismatch: "Only alphanum characters are allowed",
        },
        email: {
            nonUnique: "An account with that Email already exists",
        },
    };
    componentDidUpdate(prevProps: UserSettingsProps) {
        if (prevProps.logoId !== this.props.logoId) {
            this.setState({ logoId: this.props.logoId });
        }
    }
    private deleteAvatarImage = async () => {
        const { accountId } = this.props;
        const user = localStorage.getItem("quipa-store");
        const email = user ? JSON.parse(user).user.email : "";
        const userUpdatePayload = {
            accountId,
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            user: { email },
            logoId: null,
            email,
        };
        const res = await handleUpdateUser(userUpdatePayload);

        if (res.error) {
            if (res.payload.name === "ValidationError") {
                const errors = (res.payload as Error as ValidationError).errors;
                throw FormValidationError.fromErrorDictionary(errors);
            } else {
                throw new Error(res.payload.message);
            }
        } else {
            localStorage.removeItem("logoId");

            window.location.reload();
        }
    };
    private saveAvatarImage = async (file: File) => {
        const { accountId } = this.props;
        const formData = new FormData();
        formData.append("file", file);
        formData.append("accountId", accountId.toString());
        formData.append("fileStoreUrl", Config.fileStoreUrl);
        const res = await uploadImage(formData);

        if (res.error) {
            throw new Error(res.message);
        }
        const user = localStorage.getItem("quipa-store");
        const email = user ? JSON.parse(user).user.email : "";

        const userUpdatePayload = {
            accountId,
            logoId: res.url,
            user: { email },
        };

        const res1 = await handleUpdateUser(userUpdatePayload);

        if (res1.error) {
            if (res1.name === "ValidationError") {
                const errors = (res1 as Error as ValidationError).errors;
                throw FormValidationError.fromErrorDictionary(errors);
            } else {
                throw new Error(res1.message);
            }
        } else {
            this.setState({
                logoId: res.url,
                imageModalOpen: false,
            });
            localStorage.setItem("logoId", res.url || "");

            window.location.reload();
        }
    };
    public render(): JSX.Element {
        const { email, firstName, lastName } = this.props;
        return (
            <PageContent>
                <PageHeader title="User Settings" />
                <ContentSection
                    style={{ display: "flex", alignItems: "flex-start" }}
                >
                    <VContainer
                        uiFlex="1"
                        uiMinWidth="225px"
                        style={{ marginLeft: "10px", paddingRight: "37px" }}
                    >
                        <VSpace uiHeight="20px" />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                borderBottom: "1px solid #E2E2E2",
                                width: "100%",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <FormSubtitle>PROFILE IMAGE</FormSubtitle>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <div onClick={this.openAvatarModal}>
                                        <PencilEdit />
                                    </div>
                                    {this.state.logoId && (
                                        <>
                                            <GrayBorder />
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                                onClick={this.deleteAvatarImage}
                                            >
                                                <TrashDelete />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <AvatarContainer>
                            <UserAvatar
                                avatarId={this.state.logoId}
                                openAvatarModal={this.openAvatarModal}
                                deleteAvatarImage={this.deleteAvatarImage}
                                isSquareImgHolder={true}
                            />
                            <AvatarActionContainer></AvatarActionContainer>
                        </AvatarContainer>
                        <AvatarModal
                            uiActive={this.state.imageModalOpen}
                            uiOnRequestClose={this.closeAvatarModal}
                            onSave={this.saveAvatarImage}
                        />
                    </VContainer>
                    <div
                        style={{
                            flex: "2",
                            display: "flex",
                            alignItems: "center",
                            borderLeft: "1px solid #3b97b1",
                            paddingLeft: "37px",
                        }}
                    >
                        {" "}
                        <UserForm
                            uiPadding={false}
                            uiSubmitFailedIcon
                            uiReportValidityMode="firstInvalid"
                            uiOnSubmit={this.submit}
                            uiDimmerBlurring={false}
                        >
                            <FormField
                                uiRequired
                                uiLabel="E-Mail"
                                uiLabelFor="email"
                            >
                                <ValidationPopupStateInput
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    tabIndex={2}
                                    maxLength={255}
                                    pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                                    defaultValue={email}
                                    uiCustomAsyncValidator={
                                        this.uniquenessValidator
                                    }
                                    uiValidityMessages={
                                        this.validationMessages.email
                                    }
                                    uiReportAsyncValidationPending
                                    uiReportResultTimeout={3000}
                                    uiPopupPosition="bottom right"
                                />
                            </FormField>
                            <FormField
                                uiRequired
                                uiLabel="First Name"
                                uiLabelFor="firstname"
                            >
                                <ValidationPopupInput
                                    id="firstname"
                                    name="firstname"
                                    required
                                    tabIndex={3}
                                    maxLength={255}
                                    defaultValue={firstName}
                                    uiPopupPosition="bottom right"
                                />
                            </FormField>
                            <FormField
                                uiRequired
                                uiLabel="Last Name"
                                uiLabelFor="lastname"
                            >
                                <ValidationPopupInput
                                    id="lastname"
                                    name="lastname"
                                    required
                                    tabIndex={3}
                                    maxLength={255}
                                    defaultValue={lastName}
                                    uiPopupPosition="bottom right"
                                />
                            </FormField>
                            <Button type="submit" uiColor="primary">
                                Update
                            </Button>
                            <UpdatePasswordButton
                                type="button"
                                uiColor="secondary"
                                onClick={this.openPasswordUpdateModal}
                            >
                                Update Password
                            </UpdatePasswordButton>
                        </UserForm>
                    </div>
                </ContentSection>
                <PageDimmer uiActive={this.state.updatePasswordActive} />
                <UpdatePasswordModal
                    active={this.state.updatePasswordActive}
                    onRequestClose={this.closePasswordUpdateModal}
                    onUpdatePassword={this.updatePassword}
                />
            </PageContent>
        );
    }

    @Bind()
    private async uniquenessValidator(
        val: string,
        elem: HTMLInputElement
    ): Promise<{ [key: string]: boolean } | undefined> {
        const { email } = this.props;
        val = val.trim();
        if (elem.id === "email" && email !== val) {
            const res = await validateEmail(val.trim());
            if (res.error) {
                return {
                    nonUnique: true,
                };
            }
        }
    }

    @Bind()
    private async submit(e: FormEvent<HTMLFormElement>): Promise<void> {
        const { dispatch, firstName, lastName, accountId } = this.props;
        const user = localStorage.getItem("quipa-store");
        const email = user ? JSON.parse(user).user.email : "";
        /* tslint:disable:no-string-literal */
        const newEmail = (
            e.currentTarget.elements["email"] as HTMLInputElement
        ).value.trim();
        const newFirstName = (
            e.currentTarget.elements["firstname"] as HTMLInputElement
        ).value.trim();
        const newLastName = (
            e.currentTarget.elements["lastname"] as HTMLInputElement
        ).value.trim();
        const userSettingsPayload = {
            email: email !== newEmail ? newEmail : undefined,
            firstName: firstName !== newFirstName ? newFirstName : undefined,
            lastName: lastName !== newLastName ? newLastName : undefined,
        };

        // const res = await dispatch(UserModule.updateUser(userSettingsPayload));

        const body = {
            ...userSettingsPayload,
            user: {
                email,
            },
            email: newEmail ? newEmail : email,
        };
        const res = await handleUpdateUser(body);

        if (res && !res.email) {
            const user = localStorage.getItem("quipa-store");
            const token = user ? JSON.parse(user).user.token : "";

            let objectLocal: any = {
                user: { email: newEmail ? newEmail : email, token },
            };
            objectLocal = JSON.stringify(objectLocal);
            localStorage.setItem("quipa-store", objectLocal as string);
            localStorage.setItem("firstName", newFirstName);
            localStorage.setItem("lastName", newLastName);
        }

        const accountPayload = {
            email: email !== newEmail ? newEmail : email,
            firstName: firstName !== newFirstName ? newFirstName : firstName,
            lastName: lastName !== newLastName ? newLastName : lastName,
            prevEmail: email,
        };
        if (res.error) {
            console.log("error:", res.payload.message);
            return;
        }
        const responseCrisp = await updateCrispEmail(accountId, accountPayload);
        if (responseCrisp.status === 200) {
            const notification: NotificationsModule.Notification = {
                id: 0,
                message: "Success!",
                subMessage: "User settings updated.",
                type: "info",
            };
            dispatch(NotificationsModule.addNotification(notification));
            const profile = await getCrispProfile(accountId);
            if (profile) {
                Crisp.user.setEmail(profile.email);
                Crisp.user.setNickname(`${profile.person.nickname}`);
            }
        }
        window.location.reload();
    }

    @Bind()
    private openPasswordUpdateModal(): void {
        this.setState({
            updatePasswordActive: true,
        });
    }

    @Bind()
    private closePasswordUpdateModal(): void {
        this.setState({
            updatePasswordActive: false,
        });
    }

    @Bind()
    private async updatePassword(
        oldPass: string,
        newPass: string
    ): Promise<void> {
        // const { dispatch } = this.props;
        // const res = await dispatch(
        //     UserModule.updateUserPassword(oldPass, newPass)
        // );

        const bodyPayload = {
            user: {
                email: this.props.email,
            },
            passwordObject: {
                oldPassword: oldPass,
                password: newPass,
            },
        };
        const res = await updateUsersPassword(bodyPayload);

        if (res.error) {
            throw new Error(res.message);
        }
    }
    private openAvatarModal = () => {
        this.setState({
            imageModalOpen: true,
        });
    };
    private closeAvatarModal = () => {
        this.setState({
            imageModalOpen: false,
        });
    };
}

function mapStateToProps(state: RootState): UserSettingsProps {
    const user = localStorage.getItem("quipa-store");
    const email = user ? JSON.parse(user).user.email : "";
    return {
        email: email,
        firstName: localStorage.getItem("firstName") || "",
        lastName: localStorage.getItem("lastName") || "",
        accountId: parseFloat(localStorage.getItem("accountId") || "0"),
        logoId: localStorage.getItem("logoId") || null,
    };
}

export const UserSettingsContainer = connect(mapStateToProps)(UserSettings);
