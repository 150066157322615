import axiosInstance from "./axios-instance";

const addDraperyOrderFabric = async (payloadRecieved: any) => {
    try {
        let payload = {
            ...payloadRecieved,
            values: {
                ...payloadRecieved.values,
                fabricWidth:
                    payloadRecieved &&
                    payloadRecieved.values &&
                    payloadRecieved.values.fabricWidth
                        ? parseFloat(payloadRecieved.values.fabricWidth)
                        : 0,
                horizontalRepeat:
                    payloadRecieved &&
                    payloadRecieved.values &&
                    payloadRecieved.values.horizontalRepeat
                        ? parseFloat(payloadRecieved.values.horizontalRepeat)
                        : 0,
                verticalRepeat:
                    payloadRecieved &&
                    payloadRecieved.values &&
                    payloadRecieved.values.verticalRepeat
                        ? parseFloat(payloadRecieved.values.verticalRepeat)
                        : 0,
                trimWidth:
                    payloadRecieved &&
                    payloadRecieved.values &&
                    payloadRecieved.values.trimWidth
                        ? parseFloat(payloadRecieved.values.trimWidth)
                        : 0,
            },
        };

        const response = await axiosInstance.post<any>(
            "/draperyOrderFabric/add",
            payload
        );

        return response.data;
    } catch (error) {
        console.error("Error add drapery order fabric:", error);
        throw error;
    }
};

const editDraperyOrderFabric = async (payloadRecieved: any) => {
    try {
        let payload = {
            ...payloadRecieved,
            values: {
                ...payloadRecieved.values,
                fabricWidth:
                    payloadRecieved &&
                    payloadRecieved.values &&
                    payloadRecieved.values.fabricWidth
                        ? parseFloat(payloadRecieved.values.fabricWidth)
                        : 0,
                horizontalRepeat:
                    payloadRecieved &&
                    payloadRecieved.values &&
                    payloadRecieved.values.horizontalRepeat
                        ? parseFloat(payloadRecieved.values.horizontalRepeat)
                        : 0,
                verticalRepeat:
                    payloadRecieved &&
                    payloadRecieved.values &&
                    payloadRecieved.values.verticalRepeat
                        ? parseFloat(payloadRecieved.values.verticalRepeat)
                        : 0,
                trimWidth:
                    payloadRecieved &&
                    payloadRecieved.values &&
                    payloadRecieved.values.trimWidth
                        ? parseFloat(payloadRecieved.values.trimWidth)
                        : 0,
            },
        };

        const response = await axiosInstance.put<any>(
            "/draperyOrderFabric/edit",
            payload
        );

        return response.data;
    } catch (error) {
        console.error("Error edit drapery order fabric:", error);
        throw error;
    }
};

const deleteDraperyOrderFabric = async (payload: any) => {
    try {
        const response = await axiosInstance.post<any>(
            "/draperyOrderFabric/delete",
            payload
        );

        return response.data;
    } catch (error) {
        console.error("Error delete drapery order fabric:", error);
        throw error;
    }
};

export {
    addDraperyOrderFabric,
    editDraperyOrderFabric,
    deleteDraperyOrderFabric,
};
